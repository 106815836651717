.cohort-item {
	align-items: flex-start;
	display: flex;
	height: 113px;
	min-width: 252px;
	mix-blend-mode: normal;
	margin-left: 10%;
	cursor: pointer;
	// &:hover {
	// 	background-color: rgb(233, 233, 233);
	// }
}

.name {
	left: 0;
	letter-spacing: 0;
	mix-blend-mode: normal;
	position: absolute;
	top: 0;
	width: 204px;
}

.created {
	left: 0;
	letter-spacing: 0;
	mix-blend-mode: normal;
	position: absolute;
	top: 24px;
	width: 204px;
}
.separation {
	align-items: center;
	display: flex;
	flex-direction: column;
	// height: 32px;
	min-width: 32px;
	padding: 2.7px 5px;
}

.overlap-group {
	height: 79px;
	margin-left: 18px;
	margin-top: 4px;
	position: relative;
	width: 204px;
}

.separator-line {
	background-color: var(--mist-gray-2);
	height: 76px;
	margin-right: 1px;
	margin-top: 5px;
	mix-blend-mode: normal;
	transform: rotate(180deg);
	width: 1px;
}

.user-group-icon {
	opacity: 50%;
	font-size: var(--font-size-s);
}
