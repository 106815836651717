@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600');

.question-display {
	display: flex;
	float: left;
	align-content: space-around;
	justify-content: flex-start;
	// align-items: center;
	flex-direction: column;
	margin-left: 1.5em;
	margin-right: 1.5em;
    width: 100%;
	// overflow-y: scroll;
	// overflow-x: hidden;
	// height: 100vh;

	// width: 60%;
	h1 {
		border-bottom: 3px solid var(--header-border-bottom);
		letter-spacing: 0;
		min-height: 46px;
		mix-blend-mode: normal;
		// width: 215px;
		font-family: var(--font-family-open_sans);
	}
}

// .user-details {
// 	display: flex;
// 	flex-direction: column;
// 	align-items: center;
// 	font-family: var(--font-family-open_sans);
// }

.question-header {
	width: 100%;
	padding: 10px 0;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid var(--header-border-bottom);

	.header-block {
		text-transform: capitalize;
		width: 23%;

		&:last-child {
			width: 8%;
		}
	}
}

.buttons-container {
	display: flex;
	justify-content: space-around;
	align-content: center;
	background-color: whitesmoke;
	.single-button-container {
		margin-top: 10px;
		margin-bottom: 10px;
	}
}

.create-question-button-container {
	display: flex;
	align-items: center;
	margin-top: 10px;
    margin-bottom: 10px;
	border-bottom: 1px gray;
	.add-question-button {
		font-size: 1.5em;
		
		margin-right: 10px;
		color: #18a0fb;
		cursor: pointer;
	}
	.question-tab-button {
		font-size: 1.5em;
		
		margin-right: 10px;
		color: #585858;
		cursor: pointer;
	}
	.add-question-text {
		font-family: var(--font-family-open_sans);
	}
}

// .edit-cohort-button-container {
// 	display: flex;
// 	align-items: center;
// 	margin-right: 20%;
// 	margin-bottom: 10%;
// 	.edit-cohort-button {
// 		font-size: 1.5em;
// 		margin-left: 10px;
// 		margin-right: 10px;
// 		color: #18a0fb;
// 		cursor: pointer;
// 	}
// 	.edit-cohort-text {
// 		font-family: var(--font-family-open_sans);
// 	}
// }


//###### file input

// .upload-container{
// 	display: flex;
// 	justify-content: space-evenly
// }

.custom-file-input{
	display: none
}
  