@import '../../vars';

body {
	font-family: "Source Sans Pro";

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: var(--color-r);
	}

}



.form-control {
	box-shadow: none;
	border-width: 2.87px;
	border-color: var(--form-text-color);
	background-color: #F4F4F4;
}

.form-control:focus {
	box-shadow: none;
	border-width: 2.87px;
	border-color: var(--form-text-color);
}

.form-page-container {
	background: #F4F4F4;
	overflow: hidden;
	min-height: 100vh;
}

.survey-description {
	white-space: break-spaces;
	text-align: left;
	margin-bottom: 30px;
	font-size: var(--medium-font);
	color: var(--form-text-color);
	// font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
}


.survey-form-dropdown {
	border: 3px solid var(--form-text-color);
	border-radius: 8.61px;
	height: 50px;
	background-color: #F4F4F4;
	margin-bottom: 10px;
	margin-top: 10px;
	width: 50%;
	background: url('../../assets/dropdownicon.svg') no-repeat right;
	background-position: top 1.2em right 0.5em;
	background-size: 1em;
	-webkit-appearance: none;
	appearance: none;

}


.survey-form-open-question {
	border: 4px solid var(--form-text-color);
	border-radius: 8.61px;
	height: 100px;
	background-color: #F4F4F4;
}

.thank-you-message {
	display: flex;
	flex-direction: column;
	text-align: left;
	white-space: break-spaces;
	row-gap: 20px;
	border-top: 10px solid var(--form-border-line-thank-you);
}

.thank-you-message-header {
	margin-top: 1em;
	color: var(--color-g);
	text-align: left;

}

// DISCLAIMER SECTION-----------------

.disclaimer-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 2em;
}

.disclaimer-text {
	font-size: var(--small-font);
}

.disclaimer-logos-container {
	display: flex;
	column-gap: 2em;
}

.empirisys-sense-logo {
	margin-bottom: 3em;
	margin-top: 1em;
}

.scis-logo {
	margin-bottom: 3em;
	margin-top: 1em;
}

// ---------------------------------------------

.no-questions-message {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: var(--x-large-font);
}


.unavailable-message {
	display: flex;
	justify-content: center;
}

.container-error-page-not-found {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.question {
	width: 240px;
	height: 34px;
	font-family: "Source Sans Pro";
	font-weight: 600;
	font-size: var(--larger-font);
	color: var(--form-text-color);
	text-decoration: none solid rgb(14, 14, 14);
	line-height: 24px;
}



.question-title {
	padding-bottom: 5px;
	//border-bottom: 1px solid var(--color-d);
}

.step {
	width: 177px;
	height: 16px;
	font-family: "Source Sans Pro";
	font-size: var(--medium-font);
	color: #cfcfcf;
	text-decoration: none solid rgb(207, 207, 207);
	line-height: 16px;
}


// NEW VERSION
.forms-box {
	width: auto;
	height: auto;
	//border-radius: 4px;
	fill: #ffffff;
	//box-shadow: 0px 0px 0px rgba(45, 45, 45, 0.3);
	background-color: #F4F4F4;
	padding-top: 15px;
	padding-bottom: 15px;
	overflow: auto; //margin-bottom: 15px;
	display: flex;
	flex-direction: column;
	box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.10), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.18), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
}

.forms-box-dropdown {
	width: auto;
	height: auto;
	//border-radius: 4px;
	fill: #ffffff;
	//box-shadow: 0px 0px 0px rgba(45, 45, 45, 0.3);
	background-color: #F4F4F4;
	padding-top: 15px;
	padding-bottom: 15px;
	display: flex;
	flex-direction: column;
	box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.10), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.18), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
}


.box2 {
	width: auto;
	height: auto;
	border-radius: 4px;
	fill: #ffffff;
	box-shadow: 0px 0px 0px rgba(45, 45, 45, 0.3);
}


// OLD VERSION
// .card-button {
// 	cursor: pointer;
// 	background-color: var(--form-submit-button);
// 	background: linear-gradient(90deg, #7857FA 0%, #4A86FA 100%);
// 	// background: var(--button-basic);
// 	border: 3px var(--form-submit-button);
// 	border-style: solid;
// 	color: white;
// 	padding: 10px 10px;
// 	font-weight: 600;
// 	border: none;
// 	// width: '126px',
// 	height: 2.5em;
// 	margin-top: 1em;
// 	margin-bottom: 2em;
// 	border-radius: 0px 17.6772px;
// 	// border-radius: 2em 2em;

// 	&:focus {
// 		box-shadow: none;
// 		outline: none;
// 	}

// 	&:hover {
// 		box-shadow: 0px 15px 20px #7fa9ff;
// 		color: #fff;
// 	}
// }

// OLD VERSION
// .clear-button {
// 	$color-primary: #4A86FA;
// 	$color-secondary: #7857FA;
// 	$border: 3px;
// 	$font-size: 16px;
// 	$height: $font-size * 3;
// 	$smallheight: $height - $border - $border; // Added smallheight to make radius more natural.
// 	font-size: $font-size;
// 	margin-top: 1em;
// 	margin-bottom: 2em;
// 	text-decoration: none;
// 	font-weight: bold;
// 	color: #fff;
// 	display: inline-flex;
// 	position: relative;
// 	padding: 0 2em;
// 	cursor: pointer;
// 	background-image: linear-gradient(90deg,
// 			$color-primary 0%,
// 			$color-secondary 50%);
// 	height: 2.7em;
// 	border-radius: 0 $height/2;
// 	align-items: center;
// 	background-size: 200% 100%;
// 	transition: background-position 0.2s ease-out;
// 	background-position: 0 0;

// 	&:hover {
// 		color: #fff;
// 		//background: $color-primary;
// 		background-position: 100% 0;
// 	}

// 	&--secondary {
// 		&:before {
// 			content: "";
// 			position: absolute;
// 			left: $border;
// 			top: $border;
// 			right: $border;
// 			bottom: $border;
// 			background: #F4F4F4;
// 			border-radius: 0 $smallheight/2; // Implemented smallheight.
// 		}

// 		span {
// 			position: relative;
// 			z-index: 2;
// 			background: linear-gradient(to right, $color-primary, $color-secondary);
// 			background-clip: border-box;
// 			-webkit-background-clip: text;
// 			-webkit-text-fill-color: transparent;
// 		}

// 		&:hover {
// 			background: linear-gradient(to right, $color-primary, $color-secondary);

// 			>span {
// 				-webkit-text-fill-color: #fff;
// 			}

// 			&:before {
// 				background: rgba(255, 255, 255, 0);
// 			}
// 		}
// 	}
// }

.card-button {
	cursor: pointer;
	background: var(--button-basic);
	border: 3px var(--form-submit-button);
	border-style: solid;
	color: white;
	padding: 0 2em;
	font-weight: 600;
	border: none;
	height: 2.5em;
	border-radius: 2em 2em;
	min-width: 6em;

	&:focus {
		box-shadow: none;
		outline: none;
	}

	&:hover {
		background: var(--clear-button-hover);
		color: #fff;
	}
}

// Used only for Submit Answers in the form, conditional logic without hover effect
.card-button-disabled {
	opacity: 0.65;
	cursor: pointer;
	background: var(--color-r);
	border: 3px var(--form-submit-button);
	border-style: solid;
	color: white;
	padding: 0 2em;
	font-weight: 600;
	border: none;
	height: 2.5em;
	border-radius: 2em 2em;
	min-width: 6em;
}


.clear-button {

	cursor: pointer;
	background: transparent;
	border: 2px var(--button-basic);
	border-style: solid;
	color: white;
	// padding: 5px;
	// padding-top: 0.3em;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-left: 1em;
	padding-right: 1em;
	font-weight: 600;
	height: 2.5em;
	border-radius: 2em 2em;
	text-align: center;
	background-color: var(--background-color);
	color: var(--button-basic);
	min-width: 6em;

	&:focus {
		box-shadow: none;
		outline: none;
	}

	&:hover {
		color: var(--clear-button-hover);
		// background: var(--button-basic);
		border-color: var(--clear-button-hover);
	}
}

// Container that stores .clear-button, inside the Form.js 
.back-button-container-form {
	margin-right: 1em;
}



// NEW VERSION. 
.linkert-container {
	display: flex;
	margin-top: 20px;
	align-items: flex-end;
	overflow: auto;
	padding-bottom: 2em;
	justify-content: space-around;
}

// OLD VERSION. Media queries uncomment display inline-flex. n
// .linkert-container {
// 	display: flex;
// 	margin-top: 20px;
// 	justify-content: space-around;
// 	align-items: flex-end;
// 	vertical-align: top; // see if this helps inline-flex work on samsung
// }

.linkert-label-right,
.linkert-label-left {
	max-width: 23%; //could change 9em works with 10 and 7 radios etc., then need to change all from percentage to em
	font-size: var(--medium-font);
	text-align: center;
	overflow-wrap: break-word;
	color: var(--form-text-color);

}


.linkert-label-left-mcq-grid {

	color: var(--form-text-color);
	overflow-wrap: break-word;
	width: 8em;
}

.linkert-radios {
	display: flex;
	// margin-right: 1vw;
	// margin-left: 1vw;
	margin-top: 1em;
	border: 0px;
	width: 0.5em;
	height: 20px;
}


.linkert-na-checkbox-container {
	display: flex;
	column-gap: 2%;
	align-items: center;
	margin-left: 0.25em;

	input[type="checkbox"] {
		border: none;
		background-color: rgb(190, 190, 190);
		height: 1em;
		width: 1em;
		border-radius: 2.75555px;
	}

	input[type="checkbox"]::before {
		content: "";
		width: 0.7em;
		height: 0.7em;
		clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
		transform: scale(0);
		transform-origin: bottom left;
		transition: 120ms transform ease-in-out;
		cursor: pointer;
		box-shadow: inset 1em 1em var(--form-control-color);
		/* Windows High Contrast Mode */
		background: var(--color-g);
	}

	input[type="checkbox"]:checked::before {
		transform: scale(1);
	}
}

//---------------------------------- radio button start

//https://codepen.io/jringenberg/pen/porvRp
input[type="radio"] {
	appearance: none;
	// margin:0 40px;
	min-width: 1.5em;
	height: 1.5em;
	background: #eeeeee;
	box-shadow:
		inset 0 0 0 .4em #F4F4F4,
		0 0 0 .2em;
	border-radius: 50%;
	transition: .2s;
	cursor: pointer;
	color: var(--form-text-color);

	&:hover,
	&:checked {
		background: var(--form-text-color);
		box-shadow:
			inset 0 0 0 .6em #F4F4F4,
			0 0 0 .3em;
	}

	&:checked {
		// background: linear-gradient(180deg, #7857FA 0%, #4A86FA 100%);
		background: var(--color-p);
		box-shadow:
			inset 0 0 0 .1em #F4F4F4,
			0 0 0 .2em black;
	}

	&:focus {
		outline: 0;
	}
}


//-------------------------------- radio button end


//--------------------------------- checkbox button start 


input[type="checkbox"] {
	/* Add if not using autoprefixer */
	-webkit-appearance: none;
	/* Remove most all native input styles */
	appearance: none;
	/* For iOS < 15 */
	background-color: var(--form-background);
	/* Not removed via appearance */
	font: inherit;
	color: currentColor;
	width: 1.7em;
	height: 1.7em;
	padding: 0.6em;
	border: 0.2em solid currentColor;
	transform: translateY(-0.075em);
	cursor: pointer;
	display: grid;
	place-content: center;
}

input[type="checkbox"]::before {
	content: "";
	width: 1em;
	height: 1em;
	clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
	transform: scale(0);
	transform-origin: bottom left;
	transition: 120ms transform ease-in-out;
	cursor: pointer;
	box-shadow: inset 1em 1em var(--form-control-color);
	/* Windows High Contrast Mode */
	background: var(--color-p);
	// background: linear-gradient(180deg, #7857FA 0%, #4A86FA 100%);
}

input[type="checkbox"]:checked::before {
	transform: scale(1);
}



input[type="checkbox"]:disabled {
	--form-control-color: var(--form-control-disabled);

	color: var(--form-control-disabled);
	cursor: not-allowed;
}

//--------------------------------- checkbox button end







.linkert-radio-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: var(--medium-font);
	inline-size: 50px;
	overflow-wrap: break-word;

	input[type="radio"] {
		margin-top: 1em;
	}
}

.mcq-grid-col-headers {
	overflow-wrap: break-word;
	max-inline-size: 7em;
	text-align: center;
	padding-right: 0.5em;
}

.mcq-grid-container {
	display: flex;
	margin-top: 20px;
	// justify-content: center;
	align-items: center;
	// column-gap: 7%;

}

.mcq-grid-all-inputs-container {
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 1em;
}

.col-label-and-radio-input-same-width {
	display: flex;
	align-items: center;
	flex-direction: column;
	min-width: 7em;
}

.mcq-all-radios-container {
	display: flex;
	flex-direction: column;
	row-gap: 0.65em;
}

.mcq-radio-label-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	color: var(--form-text-color);
}

.mcq-radio-container {
	align-items: center;
	font-size: var(--medium-font);
	// inline-size: 100px;
	overflow-wrap: break-word;
	padding-right: 0.5em;
}

.linkert-radios-mcq {
	// display: flex;
	// margin-right: 1vw;
	// margin-left: 1vw;
	margin: 20px;
	margin-top: 1em;
	border: 0px;
	width: 20px;
	height: 20px;
}



// GRID LIKERT SCALE STYLING
.label-max-min-grid-likert-container {
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
}

.grid-likert-max-label {
	overflow-wrap: break-word;
	max-width: 8em;
	min-width: 5.5em;
	text-align: center;
}

.grid-likert-min-label {
	overflow-wrap: break-word;
	max-width: 8em;
	min-width: 7em;
	text-align: center;
}

.grid-likert-col-label-and-radio-input-same-width {
	display: flex;
	align-items: center;
	flex-direction: column;
	min-width: 5em;
}

// --------------------------------------

.section-title {

	//NEW
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: var(--form-header-text);
	// border-top: 10px solid var(--form-border-line);
	border-top: 10px solid var(--color-r);
	color: var(--form-text-color);
	margin-bottom: 1em;

	h1 {
		padding-top: 0.4em;
	}
}

.survey-title {

	//NEW
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 2.5em;
	margin-bottom: 1.5em;
	color: var(--form-text-color);
	text-align: center;
	row-gap: 1em;

	// color: #282A43;
}

.admin-draft-message {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	/* padding-top: 20px; */
	margin-bottom: 20px;
	padding: 1em;
	/* padding-bottom: 30px; */
	background-color: var(--color-f);
	color: white
}

.admin-closed-message {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	/* padding-top: 20px; */
	margin-bottom: 20px;
	padding: 1em;
	/* padding-bottom: 30px; */
	background-color: var(--color-b);
	color: white
}



.error {
	color: red;
	margin-top: 20px;
	font-size: var(--larger-font);
}

// .section {
// 	margin-bottom: 30px;
// 	border-left: 5px solid var(--form-border-line);
// 	border-radius: 4px;
// 	border-bottom: 1px solid var(--form-border-line);
// 	border-top: 1px solid var(--form-border-line);
// 	border-right: 1px solid var(--form-border-line);
// }
//NEW VERSION
.section {
	margin-bottom: 30px;
	border-left: 10px solid var(--form-border-line);
}


.spinner {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: auto;
	margin-top: 10vh;
	margin-bottom: 50px;
	text-align: center;
}

.spinner-text {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: var(--x-large-font);
	// h1 {
	// 	font-size: large;
	// }
}

// LARGER TABLET
@media (min-width: 902px) and (max-width: 1280px) {


	.col-md-6 {
		width: 98%;
		max-width: 98%;
		padding-left: 0px;
		padding-right: 0px;
	}

	.col-md-3 {
		width: 1%;
		max-width: 1%;
		padding-left: 0px;
		padding-right: 0px;
	}

	.linkert-radio-container input[type="radio"] {
		min-width: 1.5em;
		height: 1.5em;
		// margin-left: 0.5em;
		margin-bottom: 0.5em;
	}

	.linkert-label-left-mcq-grid {
		min-width: 7em;
		max-width: 7.5em;
	}

	.linkert-na-checkbox-container {
		margin-top: 0.5em;
	}

	.card-button,
	.card-button-disabled {
		padding: 0 2em;
	}


	.mcq-all-radios-container {
		row-gap: 0.7em;
	}
}


// TABLET
@media (min-width: 768px) and (max-width: 902px) {


	::-webkit-scrollbar {
		height: 7px;
		/* height of horizontal scrollbar ← You're missing this */
		/* width of vertical scrollbar */
	}

	.col-md-6 {
		width: 98%;
		max-width: 98%;
		padding-left: 0px;
		padding-right: 0px;
	}

	.col-md-3 {
		width: 1%;
		max-width: 1%;
		padding-left: 0px;
		padding-right: 0px;
	}

	.linkert-radio-container input[type="radio"] {
		min-width: 1.5em;
		height: 1.5em;
		// margin-left: 0.5em;
		margin-bottom: 0.5em;
	}

	.linkert-label-right,
	.linkert-label-left {
		max-width: 25%;
	}

	.linkert-label-right {
		margin-left: 1%;
	}

	.linkert-label-left {

		margin-right: 1%;
	}

	.linkert-container {
		// display: inline-flex;
		column-gap: 4%;
		justify-content: flex-start;
	}


	.linkert-label-left-mcq-grid {
		min-width: 7em;
		max-width: 7.5em;
	}

	.linkert-na-checkbox-container {
		margin-top: 0.5em;
	}


	.card-button,
	.card-button-disabled {
		padding: 0 2em;
	}

	.mcq-all-radios-container {
		row-gap: 0.7em;
	}
}

//MOBILE LARGE
@media (min-width: 413px) and (max-width: 768px) {


	::-webkit-scrollbar {
		height: 5px;
		/* height of horizontal scrollbar ← You're missing this */
		/* width of vertical scrollbar */
	}


	.survey-form-dropdown {
		width: 60%
	}

	.section {
		border-left: 8px solid var(--form-border-line);
	}

	.section-title {
		border-top: 8px solid var(--color-r);
	}

	.thank-you-message {
		border-top: 8px solid var(--form-border-line-thank-you);
	}

	.empirisys-sense-logo {
		height: 4.5em;
	}

	.scis-logo {
		height: 3.2em;
		margin-top: 1.7em;
	}

	.linkert-radio-container input[type="radio"] {
		min-width: 1.5em;
		height: 1.5em;
		// margin-left: 0.5em;
		margin-bottom: 0.5em;
	}

	.linkert-label-right,
	.linkert-label-left {
		max-width: 25%;
	}

	.linkert-label-right {
		margin-left: 1%;
	}

	.linkert-label-left {

		margin-right: 1%;
	}

	.linkert-container {
		// display: inline-flex;
		column-gap: 4%;
		justify-content: flex-start;
	}


	.linkert-label-left-mcq-grid {
		min-width: 6em;
		max-width: 8em;
	}

	.linkert-na-checkbox-container {
		margin-top: 0.5em;
	}

	.clear-button {
		font-size: 0.9em;
		height: 3em;
		padding-left: 1em;
		padding-right: 1em;
		// margin-top: 1em;
	}

	.card-button,
	.card-button-disabled {
		font-size: 0.9em;
		height: 3em;
		padding-left: 2em;
		padding-right: 2em;
		// margin-top: 1em;
	}

	.back-button-container-form {
		margin-right: 0.5em;
	}

	.mcq-all-radios-container {
		row-gap: 0.7em;
	}

}




// MOBILE MEDIUM-LARGE

@media (min-width: 412px) and (max-width: 490px) {

	::-webkit-scrollbar {
		height: 5px;
		/* height of horizontal scrollbar ← You're missing this */
		/* width of vertical scrollbar */
	}

	.survey-form-dropdown {
		width: 100%
	}


	.empirisys-sense-logo {
		height: 4.2em;
	}

	.scis-logo {
		height: 2.5em;
		margin-top: 1.7em;
	}

	.linkert-label-left-mcq-grid {
		min-width: 6em;
		max-width: 8em;
	}

	.linkert-label-right,
	.linkert-label-left {
		max-width: 35%;
	}

	.linkert-label-right {
		margin-left: 2%;
	}

	.linkert-label-left {

		margin-right: 2%;
	}

	.linkert-container {
		// display: inline-flex;
		column-gap: 7%;
		justify-content: flex-start;
	}

	.linkert-na-checkbox-container {
		margin-top: 0.5em;
	}

	.clear-button {
		font-size: 0.8em;
		height: 3em;
		padding-left: 1em;
		padding-right: 1em;
	}

	.card-button,
	.card-button-disabled {
		font-size: 0.8em;
		height: 3em;
		padding-left: 2em;
		padding-right: 2em;
	}

	.back-button-container-form {
		margin-right: 0.5em;
	}

	.mcq-all-radios-container {
		row-gap: 0.7em;
	}

}



//MOBILE MEDIUM
@media (max-width: 412px) {


	::-webkit-scrollbar {
		height: 5px;
		/* height of horizontal scrollbar ← You're missing this */
		/* width of vertical scrollbar */
	}

	.question {
		font-size: var(--medium-font);
	}

	.survey-form-dropdown {
		width: 100%
	}

	.section {
		margin-bottom: 50px;
		border-left: 5px solid var(--form-border-line);
	}

	.section-title {
		border-top: 5px solid var(--color-r);
	}

	.thank-you-message {
		border-top: 5px solid var(--form-border-line-thank-you);
	}

	.empirisys-sense-logo {
		height: 4.2em;
	}

	.scis-logo {
		height: 2em;
		margin-top: 1.7em;
	}


	.linkert-radio-container input[type="radio"] {
		min-width: 1.5em;
		height: 1.5em;
		// margin-left: 0.5em;
		margin-bottom: 0.5em;
	}

	.linkert-label-right,
	.linkert-label-left {
		max-width: 35%;
	}

	.linkert-label-right {

		margin-left: 3%;
	}

	.linkert-label-left {
		margin-right: 3%;
	}

	.linkert-container {
		// display: inline-flex;
		column-gap: 10%;
		justify-content: flex-start;

	}

	.linkert-na-checkbox-container {
		margin-top: 0.5em;
	}

	.linkert-label-left-mcq-grid {
		min-width: 6em;
		max-width: 8em;
	}


	.clear-button {
		font-size: 0.75em;
		height: 3em;
		padding-left: 1em;
		padding-right: 1em;

	}

	.card-button,
	.card-button-disabled {
		height: 3em;
		font-size: 0.75em;
		padding-left: 2em;
		padding-right: 2em;
	}

	.back-button-container-form {
		margin-right: 0.25em;
	}

	.mcq-all-radios-container {
		row-gap: 0.7em;
	}
}

// MOBILE SMALL - MEDIUM



//MOBILE SMALL (IMPHONE SE)
@media (max-width: 376px) {


	.survey-form-dropdown {
		width: 100%
	}

	.section {
		margin-bottom: 40px;
		border-left: 5px solid var(--form-border-line);
	}

	.section-title {
		border-top: 5px solid var(--color-r);
	}

	.thank-you-message {
		border-top: 5px solid var(--form-border-line-thank-you);
	}

	.disclaimer-container {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.disclaimer-logos-container {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}

	.empirisys-sense-logo {
		height: 5em;
		margin-top: 0em;
	}

	.scis-logo {
		height: 3em;
		margin-top: 1.7em;
		margin-bottom: 1em;
	}




	.linkert-radio-container input[type="radio"] {
		min-width: 1.5em;
		height: 1.5em;
		// margin-left: 0.5em;
		margin-bottom: 0.5em;
	}

	.linkert-label-right,
	.linkert-label-left {
		font-size: 80%;
		max-width: 35%;
	}


	.linkert-label-left-mcq-grid {
		min-width: 6em;
		max-width: 8em;
	}


	.clear-button {
		font-size: 0.7em;
		height: 3em;
		padding-left: 1em;
		padding-right: 1em;
		// margin-top: 1em;

	}

	.card-button,
	.card-button-disabled {
		height: 3em;
		font-size: 0.7em;
		padding: 0.8em;
		padding-left: 2em;
		padding-right: 2em;
		// margin-top: 1em;
	}

	.back-button-container-form {
		margin-right: 0.25em;
	}

	.mcq-all-radios-container {
		row-gap: 0.75em;
	}
}


@media (max-width: 345px) {

	.survey-form-dropdown {
		width: 100%
	}

	.linkert-label-left-mcq-grid {
		min-width: 6em;
		max-width: 8em;
	}

	.linkert-na-checkbox-container {
		margin-top: 0.5em;
		column-gap: 5%;

		input[type="checkbox"] {
			height: 1em;
			width: 1.3em;
		}
	}

	.clear-button {
		font-size: 10px;
		height: 3.5em;
		padding-left: 1em;
		padding-right: 1em;
	}

	.card-button,
	.card-button-disabled {
		padding-left: 1em;
		padding-right: 1em;
		font-size: 10px;
		height: 3.5em;
	}

	.back-button-container-form {
		margin-right: 0.2em;
	}


	.mcq-all-radios-container {
		row-gap: 0.8em;
	}
}

//MOBILE EXTRA SMALL (GALAXY FOLD)
@media (max-width: 290px) {

	// body {
	// 	// font-size: var(--small-font);
	// 	font-size: calc(13px + 6 * ((100vw - 320px) / 680));
	// }

	h1 {
		font-size: 2rem;
	}




	.empirisys-sense-logo {
		height: 5em;
		margin-top: 0em;
	}

	.scis-logo {
		height: 2.7em;
		margin-top: 1.7em;
		margin-bottom: 1em;
	}

	.linkert-radio-container input[type="radio"] {
		min-width: 1.5em;
		height: 1.5em;
		// margin-left: 0.5em;
		margin-bottom: 0.5em;
	}

	.linkert-na-checkbox-container {
		margin-top: 0.5em;
		column-gap: 5%;

		input[type="checkbox"] {
			height: 1em;
			width: 1.4em;
		}
	}


	.survey-form-dropdown {
		width: 100%
	}

	.linkert-label-left-mcq-grid {
		min-width: 6em;
		max-width: 8em;
	}

	.clear-button {
		font-size: 10px;
		padding-left: 0.5em;
		padding-right: 0.5em;
		height: 3.5em;
	}

	.card-button,
	.card-button-disabled {
		padding-left: 1em;
		padding-right: 1em;
		font-size: 10px;
		height: 3.5em;
	}

	.mcq-all-radios-container {
		row-gap: 1em;
	}

}