@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600');

.cohort-selection {
	display: flex;
	align-content: space-around;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;

	height: 100vh;
	h1 {
		border-bottom: 3px solid var(--header-border-bottom);
		letter-spacing: 0;
		// min-height: 46px;
		mix-blend-mode: normal;
		width: 215px;
		font-family: var(--font-family-open_sans);
	}
	.header-line {
		background-color: var(--mist-gray-2);
		height: 2px;
		mix-blend-mode: normal;
		width: 200px;
	}
}

.cohort-items {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: var(--font-family-open_sans);
	overflow-y: scroll;
	overflow-x: hidden;
	height: calc(100% - 80px);
}

.cohort-items-empty {
	display: flex;
	margin-top: 5%;
	width: 100%;
	font-size: large;
	flex-direction: column;
	align-items: center;
	font-family: var(--font-family-open_sans);
	overflow-y: scroll;
	overflow-x: hidden;
	height: calc(100% - 80px);
}


.create-cohort-button-container {
	display: flex;
	align-items: center;
	margin-right: 20%;
	margin-bottom: 10%;
	border-bottom: 1px gray;
	.add-cohort-button {
		font-size: 1.5em;
		margin-left: 10px;
		margin-right: 10px;
		color: var(--button-plus);
		cursor: pointer;
	}
	.add-cohort-text {
		font-family: var(--font-family-open_sans);
	}
}
