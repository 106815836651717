.accordion {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0.5em;
    margin-right: 0.5em;
}

.accordion-button::after {
    opacity: 0;
}

.accordion-item {
    border: 0px;
    background-color: var(--background-color);
    width: 90%;
}

.accordion-button {
    padding: 1em 1em 0em 0em;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: var(--background-color)
}

.accordion-button:not(.collapsed) {
    color: var(--black);
    background-color: var(--accordion-background-selected);
}

.accordion-button:hover {
    background-color: var(--accordion-background-selected);
}

.accordion-body {
    padding: 0;
    margin-left: 2em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: var(--background-color)
}

.accordion-collapse {
    background-color: var(--background-color)
}

.theme-item-in-accordion {
    align-items: flex-start;
    display: flex;
    height: auto;
    padding-bottom: 1em;
    min-width: 252px;
    mix-blend-mode: normal;
    cursor: pointer;
}

.overlap-group-regular-theme {
    max-width: 10em;
}

.theme-item-in-accordion-name {
    color: var(--black);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 600;

    // ELIPSIS V2 Logic multiple line control
    display: -webkit-box;
    -webkit-line-clamp: 2; //number of lines to allow before elipsis
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.theme-item-in-accordion-description {
    color: var(--black);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m2);
    font-style: normal;
    font-weight: 400;
    padding-top: 0.5em;

    // ELIPSIS V2 Logic multiple line control
    display: -webkit-box;
    -webkit-line-clamp: 2; //number of lines to allow before elipsis
    -webkit-box-orient: vertical;
    overflow: hidden;
}


.theme-created-date {
    color: var(--black);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m2);
    font-style: normal;
    font-weight: 400;
    padding-top: 0.25em;
}


.theme-item-in-accordion-name-edit-button-container {
    display: flex;
    align-items: flex-start;
}

.theme-icon {
    align-items: center;
    display: flex;
    flex-direction: column;
    min-width: 32px;
    padding: 2.7px 5px;
}



//_____________________________
.child-theme-selection-item {
    // align-items: flex-start;
    // display: flex;
    // height: 113px;
    // min-width: 252px;
    // mix-blend-mode: normal;
    // margin-left: 10%;
    // cursor: pointer;

    display: flex;
    cursor: pointer;
    padding: 1em;
}

.child-theme-selection-item-small:hover {
    background-color: var(--accordion-hover);

}

.child-theme-selection-item-small::not(.unselected) {
    background-color: var(--accordion-background-selected);

}

.name {
    left: 0;
    letter-spacing: 0;
    mix-blend-mode: normal;
    position: absolute;
    top: 0;
    width: 204px;
}

.created {
    left: 0;
    letter-spacing: 0;
    mix-blend-mode: normal;
    position: absolute;
    top: 24px;
    width: 204px;
}

.child-theme-separation-container {
    // align-items: center;
    // display: flex;
    // flex-direction: column;
    // // height: 32px;
    // min-width: 32px;
    // padding: 2.7px 5px;

    display: flex;
    flex-direction: column;
    min-width: 32px;
    align-items: flex-start;
    justify-content: flex-start;
}

.overlap-group {
    height: 79px;
    margin-left: 18px;
    margin-top: 4px;
    position: relative;
    width: 204px;
}

.child-theme-separator-line {
    background-color: var(--black);
    height: 5em;
    margin-right: 1px;
    margin-top: 10px;
    mix-blend-mode: normal;
    transform: rotate(180deg);
    width: 1px;
}

.user-group-icon {
    opacity: 50%;
    font-size: var(--font-size-s);
}