@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600');

.user-name,
.user-rank {
	font-family: var(--font-family-open_sans);
	font-size: var(--font-size-s);
	font-weight: bolder;
	color: var(--navbar-text);
}

.side-nav {
	align-content: center;
	align-items: center;
	min-height: 100vh;
	justify-content: flex-start;
	background-color: var(--navbar-background);
	display: flex;
	min-width: 12rem;
	width: 12rem;
	max-width: 15rem;
	mix-blend-mode: normal;
	flex-direction: column;
	margin: 0;
	//padding-left: 10px;
}

.sense-logo {
	width: 100%;
	align-content: center;
	padding: 5%;
	// margin-bottom: 20%;
}

.avatar {
	margin-top: 5%;
	mix-blend-mode: normal;
	object-fit: cover;
	width: 60%;
	padding: 5%;
	opacity: 0.5;
	height: auto;
}

.user-info {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: var(--navbar-text);
	font-family: var(--font-family-open_sans);
	font-size: var(--font-size-s);
	letter-spacing: 0;
	mix-blend-mode: normal;
	text-align: center;
	width: 131px;
	cursor: pointer;
}

.group-button {
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
	letter-spacing: 0;
	min-height: 5vh;
	min-width: 5vw;
	width: 98%;
	mix-blend-mode: normal;
	text-align: center;
	align-items: center;
	padding-top: 15%;
	padding-bottom: 15%;
	padding-left: 10px;
	padding-right: 10px;
	border-radius: 15px 0% 0% 15px;
	margin-left: 2%;
	cursor: pointer;

	.group-button-icon {
		color: var(--navbar-button);
		opacity: 0.9;
		font-size: 5vh;
	}

	.group-button-text {
		font-family: var(--font-family-open_sans);
		font-size: var(--font-size-s);
		letter-spacing: 0;
		margin-bottom: 5%;
		color: var(--navbar-text);
	}
}

.bottom-image {
	margin-left: 2%;
	font-size: 1.5em;
	padding-top: 10%;
	color: var(--edit-button);
}

.settings {
	margin-top: 20%;
	margin-bottom: 20%;
	height: 5vh;

	cursor: pointer;
}

.settings path {
	opacity: 0.9;
	stroke: var(--navbar-button);
}

.controls-container {
	display: flex;
	flex-direction: column;
	align-content: center;
	align-items: center;
	width: 100%;
}


.popover-body {
	display: flex;
	flex-direction: column-reverse;
	width: 13em;
}


.organisation-dropdown {
	background: url('../../../assets/orgdropdownicon.svg') no-repeat right;
	background-position: top 1em right 0.5em;
	background-size: 0.8em;
	text-overflow: ellipsis;
}