.survey-display {
	display: flex;
	float: left;
	align-content: space-around;
	justify-content: flex-start;
	flex-direction: column;
	margin-left: 2em;
	width: 100%;
	background-color: var(--background-color);
}


.survey-display-header {

	border-bottom: 3px solid var(--header-border-bottom);
	margin-right: 1em;
	margin-top: 1em;
	padding-bottom: 0.5em;
	font-size: var(--font-size-l2);

	// elipsis logic
	// max-width: 20em;
	// overflow: hidden;
	// display: inline-block;
	// text-overflow: ellipsis;
	// white-space: nowrap;
}


.add-survey-button-container {
	display: flex;
	align-items: center;
	margin-top: 1em;
	margin-bottom: 1em;
	margin-left: 0.5em;
	cursor: pointer;

	.add-button-text-new-survey {
		margin-left: 0.4em;
		font-family: var(--font-family-open_sans);
		font-family: "Source Sans Pro";
		font-weight: 600;
		font-size: 20px;

	}
}



.custom-file-input {
	display: none
}