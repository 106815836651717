.button-section {
  margin: 3%;
}

.button {
  padding: 10px 20px;
  background-color: dodgerblue;
  border: 1px solid #ddd;
  color: white;
  cursor: pointer;
}

.button:hover {
  background-color: royalblue;
}

.remove {
  background-color: rgb(192, 53, 53);
}

.remove:hover {
  background-color: rgb(187, 43, 43);
}

// input placeholders for all browsers============

.choice-inputs {
  background-color: var(--section-color);
}

.choice-inputs::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  opacity: 0.5;
}

.choice-inputs:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  opacity: 0.5;
  opacity: 0.5;
}

.choice-inputs::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  opacity: 0.5;
  opacity: 0.5;
}

.choice-inputs:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  opacity: 0.5;
}

.choice-inputs::-ms-input-placeholder {
  /* Microsoft Edge */
  opacity: 0.5;
}

.choice-inputs::placeholder {
  /* Most modern browsers support this now. */
  opacity: 0.5;
}

.add-choice-button-container {
  display: flex;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 1em;
  cursor: pointer;

  .add-choice-button-text {
    margin-left: 0.4em;
    font-family: var(--font-family-open_sans);
    font-family: "Source Sans Pro";
    font-weight: 600;
  }

}