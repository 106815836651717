:root {
	--black: #000000;
	--mist-gray-2: #E5E5E5;
	/*#c4c4c4;*/


	/* Creating a series of colours for a theme */
	/* Start: */
	--color-a: #FFC800;
	--color-b: #FE4A49;
	--color-c: #57D5D1;
	--color-d: #426B69;
	--color-e: #242038;
	--color-f: #FFA500;
	--color-g: #282A43;
	--color-h: #F4F4F4;
	--color-i: #EAEAED;
	--color-j: #C9C9D0;
	--color-k: #e7e7e747;
	--color-l: #4A86FA;
	--color-m: #202944;
	--color-n: #6C63FF;
	--color-o: #06D6A0;
	--color-p: #004F5A;
	--color-r: #A1D0CB;
	--color-s: #052A30;
	--color-t: #052A30;
	--color-u: #092A36;





	--live: #06D6A0;
	--closed: #FD6483;
	--draft: #202944;
	--draft-opacity: 50%;

	--background-color: #F4F4F5;



	/*--navbar-background: var(--color-g);*/
	--navbar-selected: var(--color-h);



	/* End. */

	/* Reference for All Colours */

	/* Section Color */
	--section-color: var(--color-i);

	--spinner-color: var(--color-g);
	/* Navbar: */
	--navbar-button: var(--color-c);
	--navbar-background: var(--color-g);
	--navbar-text: var(--color-i);


	--navbar-select-component-background: var(--color-g);
	--navbar-select-component-text: var(--color-h);
	--navbar-select-component-border-color: var(--color-h);




	/* Buttons: */
	--button-basic: var(--color-s);

	--clear-button-hover: var(--color-p);

	--button-plus: var(--color-b);

	--edit-button: var(--color-f);

	--share-button: var(--color-d);

	--delete-button: var(--color-b);

	--back-button: var(--color-d);


	--secondary-button-text: white;
	--secondary-button: var(--color-d);
	--secondary-button-border: var(--color-d);



	--button-hover-opacity: 0.9;

	--form-clear-button: var(--color-d);



	--modal-create-button: var(--color-b);
	--modal-create-button-text: white;
	--modal-create-button-border: var(--color-b);
	--modal-cancel-button: var(--color-d);
	--modal-cancel-button-text: white;
	--modal-cancel-button-border: var(--color-d);




	/* Text & Borders */
	--header-border-bottom: var(--color-j);

	--font-size-l: 16px;
	--font-size-m2: 14px;
	--font-size-l2: 28px;

	--font-family-open_sans: "Source Sans Pro";
	--form-text-color: var(--color-t);



	/* Form Style */
	--form-header-background: var(--color-d);
	--form-header-text: white;

	--form-border-bottom: var(--color-d);


	--form-border-line: var(--color-p);
	/* SASS  var(--color-r), SCIS #ED1C24*/
	--form-section-title-line: #ED1C24;
	--form-border-line-thank-you: var(--color-o);

	--form-submit-button: var(--color-b);

	/* FORM FONT SIZES */
	--header-font: 2em;

	--x-large-font: 24px;
	--larger-font: 20px;
	--medium-font: 1em;
	--small-font: 14px;



	/* Question Manager */

	--accordion-background-selected: var(--color-i);

	--subtheme-bar-color: var(--color-e);

	--accordion-hover: var(--color-k);
}


.opensans-semi-bold-black-16px {
	color: var(--black);
	font-family: var(--font-family-open_sans);
	font-size: var(--font-size-l);
	font-style: normal;
	font-weight: 600;
}

.opensans-normal-black-14px {
	color: var(--black);
	font-family: var(--font-family-open_sans);
	font-size: var(--font-size-m2);
	font-style: normal;
	font-weight: 400;
}