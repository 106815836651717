.question-text-container {
    display: flex;
    flex-direction: column
}

.short-answer {
    color: #18a0fb;
    font-size: small;
    margin-top: 10px;
    margin-bottom: 10px;
}

.empty-subthemes-message {
    color: #c92a2a;
    font-size: large;
    margin-top: 10px;
    margin-bottom: 10px;
}

.tabs-menu {
    margin-left: 0.8em
}

// ----------------- BUTTON SWITCH ----------------- 
.btn {
    border: 3px solid var(--color-m);
    display: inline-block;
    position: relative;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    // transition: 300ms ease, color 300ms ease;
}

input[type="radio"].toggle {
    display: none;

    // border: 3px solid var(--color-m);
    &+label {
        cursor: pointer;
        min-width: 60px;

        &:hover {
            background: none;
            color: var(--color-m);
        }

        &:after {
            background: var(--color-m);
            content: "";
            height: 100%;
            position: absolute;
            top: 0;
            transition: left 200ms cubic-bezier(0.77, 0, 0.175, 1);
            width: 100%;
            z-index: -1;
        }
    }

    &.toggle-left+label {
        border-right: 0;

        &:after {
            left: 100%
        }
    }

    &.toggle-right+label {
        margin-left: -5px;

        &:after {
            left: -100%;
        }
    }

    &:checked+label {
        cursor: default;
        color: #fff;
        transition: color 200ms;
        background: var(--color-m);

        &:after {
            left: 0;
        }
    }
}

.question-modal-any-dropdown {
    border: 2.87px solid #282A43;
    // border-radius: 8.61px; //probably could leave
    height: 50px;
    background-color: #F4F4F4;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 50%;
    background: url('../../../assets/dropdownicon.svg') no-repeat right;
    background-position: top 1.2em right 0.5em;
    background-size: 1em;
    -webkit-appearance: none;
    appearance: none;
    padding-right: 1.5em;
    text-overflow: ellipsis;

}

// class for row index number in choice/grid questions:


// ROWS FOR MULTIPLE AND GRID CHOICES STYLING :

.form-inline-choice-rows {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

}

.form-inline-choice-rows label {
    margin: 5px 10px 5px 0;
}

.form-inline-choice-rows input {
    vertical-align: middle;
    margin: 5px 10px 5px 0;
    padding: 10px;
    width: 80%;
    background-color: var(--background-color);
    border: 2px solid var(--color-m);
    border-radius: 8px;
    border-width: 2.87px;
}

.row-number-label {
    width: 1.5em;
    display: flex;
    margin: 0px;
    justify-content: center;
}

// CONFIRM ADD ANOTHER QUESTION SUCCESS POP UP STYLES::after
.confirm-pop-up-body {
    display: flex;
    justify-content: center;
    font-weight: 600;
}

.confirm-pop-up-footer {
    display: flex;
    justify-content: center;
}