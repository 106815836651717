@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-navigations/styles/material.css';

body {
	font-family: "Source Sans Pro";
	margin: 0;
	overflow-x: hidden;
	/* Hide horizontal scrollbar */
}


a {
	text-decoration: none;
	color: #000;

}

* {
	box-sizing: border-box;
}



/* width */
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgb(202, 202, 202);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #888;
}

select {
	/* -webkit-appearance: listbox !important */
}

::-webkit-scrollbar {
	height: 10px;
	/* height of horizontal scrollbar ← You're missing this */
	width: 4px;
	/* width of vertical scrollbar */
	border: 1px solid var(--color-g);
}