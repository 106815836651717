.theme-question-display-container {
	display: flex;
	float: left;
	align-content: space-around;
	justify-content: flex-start;
	// align-items: center;
	flex-direction: column;
	margin-left: 1.5em;
	height: 100vh;
	width: 100%;

}

.theme-question-display-text {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 50px;
	font-size: larger;
}

.theme-question-display {

	overflow-y: scroll;



}

.btn-secondary {
	height: 40px;
}

.file-text-container {
	margin: 10px
}

.spinner-container {
	display: flex;
	justify-content: center;
	margin: 50px;
	flex-direction: column;
	align-items: center;
}

.no-recipients {
	display: flex;
	justify-content: center;
	align-content: center;
	margin: 50px;
	font-weight: bolder;
}

.table-style {
	width: 100%
}


.theme-question-header {

	border-bottom: 3px solid var(--header-border-bottom);
	margin-right: 1em;
	margin-top: 1em;
	padding-bottom: 0.5em;
	font-size: var(--font-size-l2);

	// elipsis logic
	// max-width: 20em;
	// overflow: hidden;
	// display: inline-block;
	// text-overflow: ellipsis;
	// white-space: nowrap;
}




.add-question-button-container-question-display {
	display: flex;
	align-items: center;
	margin-top: 0.4em;
	margin-bottom: 10px;
	margin-left: 0.5em;
	cursor: pointer;

	.add-button-text-question-display {
		margin-left: 0.4em;
		font-family: var(--font-family-open_sans);
		font-family: "Source Sans Pro";
		font-weight: 600;
		font-size: 20px;

	}
}





.buttons-container-theme-questions {
	display: flex;

	.single-button-container-theme-questions {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	width: 95%;

}




//###### file input

.upload-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 20px;
}

.custom-file-input {
	display: none
}



.edit-theme-question-button {
	font-size: 1.5em;

	margin-right: 10px;
	color: var(--edit-button);
	cursor: pointer;
	text-align: right;
}

.remove-theme-question-button {
	font-size: 1.5em;

	margin-right: 10px;
	color: var(--delete-button); //#ff9933
	cursor: pointer;
	text-align: right;
}