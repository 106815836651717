.params {
	display: flex;
	flex-direction: column;
	justify-content: flex-start
}

.tooltip-inner {
	text-align: start;
}


//SURVEY STATUS PROPS

.status-container {
	display: flex;
	flex-direction: row;
	align-content: center;
	justify-content: flex-start;
	align-items: center;
}

.dot-live {
	height: 10px;
	width: 10px;
	background-color: var(--live);
	border-radius: 50%;
	display: inline-block;
	margin-left: 31px;
}

.dot-closed {
	height: 10px;
	width: 10px;
	background-color: var(--closed);
	border-radius: 50%;
	display: inline-block;
	margin-left: 10px;
}

.dot-draft {
	height: 10px;
	width: 10px;
	background-color: var(--draft);
	opacity: var(--draft-opacity);
	border-radius: 50%;
	display: inline-block;
	margin-left: 25px;
}

//  OLD VERSION
// .card-share-button {
// 	cursor: pointer;
// 	background: linear-gradient(90deg, #7857fa, #4a86fa);
// 	color: #fff;
// 	padding: 4px 10px;
// 	font-weight: 500;
// 	border: none;
// 	height: 2.3em;
// 	margin-top: 0.25em;
// 	margin-bottom: 0.25em;
// 	border-radius: 0 17.6772px;
// }

// .card-share-button:hover {
// 	box-shadow: 0 5px 7px #bfb0f8;
// 	color: #fff;
// }


// Same as .card-button

.card-share-button {
	cursor: pointer;
	background: var(--button-basic);
	border: 3px var(--form-submit-button);
	border-style: solid;

	color: white;
	padding: 5px 10px;
	font-weight: 600;
	border: none;

	height: 2.45em;

	border-radius: 2em 2em;

	&:focus {
		box-shadow: none;
		outline: none;
	}

	&:hover {
		background: var(--clear-button-hover);
		color: #fff;
	}
}

// -------------



.td-customized:nth-child(2) {
	max-height: 5em;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	// margin-top: 15px;
	// margin-bottom: 15px;
	min-width: 50px;
	max-width: 800px;
}

.td-customized:first-child {
	max-height: 5em;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	// margin-top: 15px;
	// margin-bottom: 15px;
	min-width: 50px;
	max-width: 400px;


}