.subthemes {
	display: flex;
	float: left;
	align-content: space-around;
	justify-content: flex-start;
	// align-items: center;
	flex-direction: column;
	margin-left: 1.5em;
	margin-right: 1.5em;
    width: 100%;
	// overflow-y: scroll;
	// overflow-x: hidden;
	// height: 100vh;

	// width: 60%;
	h1 {
		border-bottom: 3px solid var(--header-border-bottom);
		letter-spacing: 0;
		min-height: 46px;
		mix-blend-mode: normal;
		// width: 215px;
		font-family: var(--font-family-open_sans);
	}
}

.back-button{
	font-size:0.8em;
    margin-right: 10px;
    color: var(--back-button);
    cursor: pointer;
}