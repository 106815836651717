.question-text-container {
    display: flex;
    flex-direction: column
}

.short-answer {
    color: #18a0fb;
    font-size: small;
    margin-top: 10px;
    margin-bottom: 10px;
}

.empty-subthemes-message {
    color: #c92a2a;
    font-size: large;
    margin-top: 10px;
    margin-bottom: 10px;
}

.tabs-menu {
    margin-left: 0.8em
}

// ----------------- BUTTON SWITCH ----------------- 
.btn {
    border: 3px solid var(--color-m);
    display: inline-block;
    position: relative;
    text-align: center;
    margin-top: 10px;
    // transition: 300ms ease, color 300ms ease;
}

input[type="radio"].toggle {
    display: none;

    // border: 3px solid var(--color-m);
    &+label {
        cursor: pointer;
        min-width: 60px;

        &:hover {
            background: none;
            color: var(--color-m);
        }

        &:after {
            background: var(--color-m);
            content: "";
            height: 100%;
            position: absolute;
            top: 0;
            transition: left 200ms cubic-bezier(0.77, 0, 0.175, 1);
            width: 100%;
            z-index: -1;
        }
    }

    &.toggle-left+label {
        border-right: 0;

        &:after {
            left: 100%
        }
    }

    &.toggle-right+label {
        margin-left: -5px;

        &:after {
            left: -100%;
        }
    }

    &:checked+label {
        cursor: default;
        color: #fff;
        transition: color 200ms;
        background: var(--color-m);

        &:after {
            left: 0;
        }
    }
}