
  // -------------

  .organisations-header {
	border-bottom: 3px solid var(--header-border-bottom);
	letter-spacing: 0;
	min-height: 46px;
	mix-blend-mode: normal;
	// width: 215px;
	font-family: var(--font-family-open_sans);
}