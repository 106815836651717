@import '../../vars';
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600');
* {
    box-sizing: border-box;
}

.loginContainer {
    min-height: calc(100vh - 60px);
    display: flex;
    justify-content: center;
    text-align: left;
}

.form-group label {
   font-family: var(--font-family-open_sans);
}

.auth-inner {
    width: 90%;
    max-width: 400px;
    padding: 1.5em;
    margin: auto;
    background: #fff;
    transition: all 0.3s;
    border-radius: 4px;
    box-shadow: 0px 0px 12px rgba(45, 45, 45, 0.3);
    h3 {
        font-family: var(--font-family-open_sans);
    }
}
.form-control:focus {
    box-shadow: none;
    border-color: #8c8c8c;
}

.auth-wrapper h3 {
    text-align: left;
    margin: 0;
    line-height: 1;
    padding-bottom: 20px;
}

.custom-control-label {
    font-weight: 400;
}

.forgot-password,
.forgot-password a {
    text-align: right;
    font-size: 13px;
    color: #7f7d7d;
    margin: 0;
}

.submit-btn {
    background-color: $brand-primary;
    width: 30%;
    margin: 0;
    color: white;
    font-weight: 600;
    border: none !important;
    &:hover,
    :active {
        background-color: $brand-primary !important;
        // color: white
        color: rgba(255, 255, 255, 1);
        box-shadow: 10px 5px 15px rgba(110, 110, 110, 0.4);
    }
    &:focus {
        box-shadow: none !important;
    }
}

.error-notice {
    padding: 15px 0 0 0;
    color: red;
}

#tos {
    display: flex;
    margin-bottom: 1rem;
}

#buttonSpan {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
