.params {
  display: flex;
  flex-direction: column;
  justify-content: flex-start
}

.tooltip-inner {
  text-align: start;
}

.pagination-buttons {
  display: flex;
  gap: 10px;
  align-items: center;

}

.button-container {}

.go-to-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dropdown-container {}


// EXPERIMENTAL

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 19px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  margin-right: -3px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 3px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


.filterStyles {
  height: 30px;
  margin-bottom: 5px;
  width: 10vw;
}

.formControl {
  border-width: 2px;
  border-radius: 8px;
  background-color: var(--background-color);
  margin-right: 0.5em;
  margin-bottom: 0.5em;

  max-width: 10em;
  min-width: 5em;
  // elipsis logic
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;

}

.td-questions-customized {
  // max-height: 5em;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  // margin-top: 15px;
  // margin-bottom: 15px;
  min-width: 50px;
  max-width: 800px;
}