// @font-face {
// 	font-family: 'Poppins';
// 	// src: url('../src/assets/fonts/OpenSans-Regular.woff') format('woff');
// }

//adding fonts https://css-tricks.com/snippets/css/using-font-face/




body {
	background-color: var(--background-color);

}



$brand-primary: #282a43;
$nav-primary: rgb(255, 255, 255);
$sidebar: #f5f5f5;
$modal-background-color: #F4F4F5;

$xSmall: 0px;
$small: 568px;
$medium: 768px;
$large: 1024px;
$xLarge: 1200px;




.modal-create-button {
	color: var(--modal-create-button-text);
	background-color: var(--modal-create-button);
	border-color: var(--modal-create-button-border);
}

.modal-cancel-button {
	color: var(--modal-cancel-button-text);
	background-color: var(--modal-cancel-button);
	border-color: var(--modal-cancel-button-border);
	margin-right: 1em;
}

.modal-create-button:hover {
	color: var(--modal-create-button-text);
	background-color: var(--modal-create-button);
	border-color: var(--modal-create-button-border);
	opacity: var(--button-hover-opacity);
}

.modal-cancel-button:hover {
	color: var(--modal-cancel-button-text);
	background-color: var(--modal-cancel-button);
	border-color: var(--modal-cancel-button-border);
	opacity: var(--button-hover-opacity);
}

.secondary-button {
	color: var(--secondary-button-text);
	background-color: var(--secondary-button);
	border-color: var(--secondary-button-border);
}

.secondary-button:hover {
	color: var(--secondary-button-text);
	background-color: var(--secondary-button);
	border-color: var(--secondary-button-border);
	opacity: var(--button-hover-opacity);
}



// --------------------------------------------------------------------------------------------------------
//    Modal Component Settings
// --------------------------------------------------------------------------------------------------------
// .modal-footer {
// 	background: var(--modal-background-color);
// 	border-top: 0px;
// }

// .modal-header {
// 	background: var(--modal-background-color);
// }

// .modal-body {
// 	background: var(--modal-background-color);
// }

.modal-content {
	background-color: $modal-background-color;
}

.modal-title {
	font-weight: 500;
	font-family: "Source Sans Pro";
	font-style: normal;
	font-weight: 400;
	font-size: 32px;
	line-height: 48px;
}

.modal-text {
	font-weight: 600;
}