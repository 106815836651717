.selected-questions-list {
	margin-top: 10px;
}

.main-modal {
	min-width: 80%;
}

.edit-survey-save-cancel-button-container {
	display: flex;
}