//_____________________________ CSS FOR QUESTION CARDS IN SECTION CREATION

.section-container {
	background-color: var(--section-color);
	display: flex;
	width: 95%;
	margin-top: 1em;
}

.section-question-container {
	background: #F4F4F4;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	border-radius: 0%;
	border: none;

}

.section-inputs {
	background-color: var(--section-color);
}

.section-inputs::-webkit-input-placeholder {
	/* WebKit, Blink, Edge */
	opacity: 0.5;
}

.section-inputs:-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	opacity: 0.5;
	opacity: 0.5;
}

.section-inputs::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	opacity: 0.5;
	opacity: 0.5;
}

.section-inputs:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	opacity: 0.5;
}

.section-inputs::-ms-input-placeholder {
	/* Microsoft Edge */
	opacity: 0.5;
}

.section-inputs::placeholder {
	/* Most modern browsers support this now. */
	opacity: 0.5;
}

.section-question-contents {
	width: 100%;
}

//header CSS
.section-question-header-container {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	align-content: center;
	width: auto
}

.question-header-contents {
	display: flex;
	justify-content: space-between;
	width: 100%;
	font-size: 2em;
}

.question-drag-handle-container {
	padding-right: 1em;
}

.edit-section-question-button {
	font-size: 1.5em;
	// padding-left: 1rem;
	margin-right: 10px;
	color: var(--edit-button);
	cursor: pointer;
}


//Theme & Subtheme CSS

//sq stands for section question

.sq-theme-container {
	display: flex;
	gap: 0.5em;
	align-items: flex-start;
	font-size: 0.8em;
}

.sq-theme {
	background: #E76363;
	padding-top: 0.25em;
	margin-top: 0.25em;

}

.sq-subtheme {
	background: #A8D981;
	padding-top: 0.25em;
	margin-top: 0.25em;
}

//QUESTION PARAMETERS (ACCORDION PART)

.sq-parameters-container {
	display: flex;
	flex-direction: column;
	font-size: 1em;
	margin-top: 0.5em;
	border-bottom: 2px solid #ddd;
	padding-bottom: 0.5em;
	gap: 0.5em;
}

.delete-section-question-button {
	font-size: 1.5em;
	// padding-left: 1rem;
	margin-right: 10px;
	color: var(--delete-button);
	cursor: pointer;
}

//QUESTION CONTROLS (BRANCHING/REQUIRED CHECKBOXES, DELETE BUTTON)

.question-controls-contents {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-top: 0.5em;
}

.checkbox-containers {
	display: flex;
	justify-content: space-between;
	column-gap: 0.5em;
}

#required-checkbox {
	margin-left: 0.25em;
	background-color: #4444444d;
	width: 1.5em;
	height: 1.5em;
	border: none;
	transform: none;
	border-radius: 5px;
}

#shuffle-answers-checkbox {
	margin-left: 0.25em;
	background-color: #4444444d;
	width: 1.5em;
	height: 1.5em;
	border: none;
	transform: none;
	border-radius: 5px;
}

.drilldown-button {
	margin-left: 0.25em;
	margin-right: 0.25em;
	width: 1.5em;
}

.branching-button {
	margin-left: 0.25em;
	margin-right: 0.25em;
	width: 1.5em;
}

.add-question-button-container {
	display: flex;
	align-items: center;
	margin-top: 1em;
	margin-bottom: 10px;
	margin-left: 0.7em;
	cursor: pointer;

	.add-button-text {
		margin-left: 0.4em;
		font-family: var(--font-family-open_sans);
		font-family: "Source Sans Pro";
		font-weight: 600;
		font-size: 20px;

	}
}

.delete-question-button-container {
	display: flex;
	align-items: center;
	margin-top: 1em;
	margin-bottom: 10px;
	margin-left: 0.7em;
	cursor: pointer;

	.delete-button-text {
		margin-left: 0.4em;
		font-family: var(--font-family-open_sans);
		font-family: "Source Sans Pro";
		font-weight: 400;
		font-size: 12px;

	}
}


// ACCORDION HANDLE

.question-accordion-handle {
	margin-left: 0.5em;
	cursor: pointer;
	margin-top: 1em;
}

.grip-section-button {
	margin-top: 2em;
	margin-left: 1em
}

.grip-question-button {
	margin-top: 2em;
	margin-left: 1em
}

// MCQ QUESTION ACCORDION CSS

.answers-container {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin-right: 1em
}

.branching-container {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.answers-branching-main-container {
	display: flex;
	justify-content: space-evenly;
}


.survey-status-dropdown {

	border: 2.87px solid #282A43;
	// border-radius: 8.61px; //probably could leave
	height: 50px;
	background-color: #F4F4F4;
	margin-bottom: 10px;
	margin-top: 10px;
	width: 17%;
	background: url('../../../assets/dropdownicon.svg') no-repeat right;
	background-position: top 1.2em right 0.5em;
	background-size: 1em;
	-webkit-appearance: none;
	appearance: none;
	padding-right: 1.5em;
	text-overflow: ellipsis;
}

.branching-dropdown {
	border: 0.2em solid #282A43;
	border-radius: 0.5em;
	height: 2em;
	background-color: #F4F4F4;
	margin-bottom: 0.1em;
}

.question-with-handle-container {
	background-color: #F4F4F4;
	display: flex;
	justify-content: flex-start;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	overflow: auto;
	margin-top: 2em;
}

// TAB CSS


.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active,
.nav-link:focus,
.nav-link:hover {
	color: #495057;
	background-color: #F4F4F4;
	border-width: 2px 2px 0px 2px;
	border-style: solid;
	border-color: #C9C9D0;
	border-radius: 8px 8px 0px 0px;
	font-weight: 600;
}

.nav-tabs .nav-link {
	color: #495057;
	font-weight: 600;
}