.formTitle {
    // width: 240px;
    height: 34px;
    font-family: var(--font-family-open_sans);
    font-size: 24px;
    color: #0e0e0e;
    text-decoration: none solid rgb(14, 14, 14);
    line-height: 24px;
}

.step {
    width: 177px;
    height: 16px;
    font-family: var(--font-family-open_sans);
    font-size: 16px;
    color: #cfcfcf;
    text-decoration: none solid rgb(207, 207, 207);
    line-height: 16px;
}

.box {
    width: auto;
    height: auto;
    border-radius: 4px;
    fill: #ffffff;
    box-shadow: 0px 0px 12px rgba(45, 45, 45, 0.3);
}

.box2 {
    width: auto;
    height: auto;
    border-radius: 4px;
    fill: #ffffff;
    box-shadow: 0px 0px 12px rgba(45, 45, 45, 0.3);
}