.theme-selection {
	display: flex;
	align-content: space-around;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;

	height: 100vh;

	h1 {
		border-bottom: 3px solid var(--header-border-bottom);
		margin-left: 1em;
		margin-right: 1em;
		margin-top: 1em;
		padding-bottom: 0.5em;
		font-size: var(--font-size-l2);
	}

	.header-line {
		background-color: var(--mist-gray-2);
		height: 2px;
		mix-blend-mode: normal;
		width: 200px;
	}
}

.theme-items {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: var(--font-family-open_sans);
	overflow-y: scroll;
	overflow-x: hidden;
	height: calc(100% - 80px);
}

.cohort-items-empty {
	display: flex;
	margin-top: 5%;
	width: 100%;
	font-size: large;
	flex-direction: column;
	align-items: center;
	font-family: var(--font-family-open_sans);
	overflow-y: scroll;
	overflow-x: hidden;
	height: calc(100% - 80px);
}


.create-cohort-button-container {
	display: flex;
	align-items: center;
	margin-right: 20%;
	margin-bottom: 10%;
	border-bottom: 1px gray;

	.add-cohort-button {
		font-size: 1.5em;
		margin-left: 10px;
		margin-right: 10px;
		color: var(--button-plus);
		cursor: pointer;
	}

	.add-cohort-text {
		font-family: var(--font-family-open_sans);
	}
}

.add-theme-button-container {
	display: flex;
	align-items: center;
	margin-top: 1em;
	margin-bottom: 10px;
	/* margin-left: 0.7em; */
	cursor: pointer;
}

.add-theme-button-text {
	margin-left: 0.4em;
	font-family: var(--font-family-open_sans);
	font-family: "Source Sans Pro";
	font-weight: 600;
	font-size: 20px;
}