.params{
	display: flex;
    flex-direction: column;
	justify-content: flex-start
}

.tooltip-inner {
	text-align: start;
}

.questions-container{
	display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    align-content: flex-start;
}