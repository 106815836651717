@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600');

.cohort-display-container {
	display: flex;
	float: left;
	align-content: space-around;
	justify-content: flex-start;
	// align-items: center;
	flex-direction: column;
	margin-left: 1.5em;
	width: 98%;
	// overflow-y: scroll;
	// overflow-x: hidden;
	// height: 100vh;

	// width: 60%;
	h1 {
		border-bottom: 3px solid var(--header-border-bottom);
		letter-spacing: 0;
		min-height: 46px;
		mix-blend-mode: normal;
		// width: 215px;
		font-family: var(--font-family-open_sans);
	}
}

.cohort-display-text {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 50px;
	font-size: larger;
}

// .cohort-display{

// }

.btn-secondary {
	height: 40px;
}

.file-text-container {
	margin: 10px
}

.spinner-container {
	display: flex;
	justify-content: center;
	margin: 50px;
	flex-direction: column;
	align-items: center;
}

.no-recipients {
	display: flex;
	justify-content: center;
	align-content: center;
	margin: 50px;
	font-weight: bolder;
}

.table-style {
	width: 100%
}

// .user-details {
// 	display: flex;
// 	flex-direction: column;
// 	align-items: center;
// 	font-family: var(--font-family-open_sans);
// }

.cohort-header {
	width: 100%;
	padding: 10px 0;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid var(--header-border-bottom);

	.header-block {
		text-transform: capitalize;
		width: 23%;

		&:last-child {
			width: 8%;
		}
	}
}

.buttons-container-cohorts {
	display: flex;

	.single-button-container-cohorts {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	width: 95%;

}

// .edit-cohort-button-container {
// 	display: flex;
// 	align-items: center;
// 	margin-right: 20%;
// 	margin-bottom: 10%;
// 	.edit-cohort-button {
// 		font-size: 1.5em;
// 		margin-left: 10px;
// 		margin-right: 10px;
// 		color: #18a0fb;
// 		cursor: pointer;
// 	}
// 	.edit-cohort-text {
// 		font-family: var(--font-family-open_sans);
// 	}
// }


//###### file input

.upload-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 20px;
}

.custom-file-input {
	display: none
}



.edit-cohort-button {
	font-size: 1.5em;

	margin-right: 10px;
	color: var(--edit-button);
	cursor: pointer;
	text-align: right;
}

.remove-cohort-button {
	font-size: 1.5em;

	margin-right: 10px;
	color: var(--delete-button); //#ff9933
	cursor: pointer;
	text-align: right;
}