.button-section {
  margin: 3%;
}

.button {
  padding: 10px 20px;
  background-color: dodgerblue;
  border: 1px solid #ddd;
  color: white;
  cursor: pointer;
}

.button:hover {
  background-color: royalblue;
}

.remove {
  background-color: rgb(192, 53, 53);
}

.remove:hover {
  background-color: rgb(187, 43, 43);
}


.grid-col-row-labels {
  margin-left: 1em;
}

// input placeholders for all browsers (identical to grid-col-row-inputs, but kept separate for custimisation if needed============

.grid-col-row-inputs {
  background-color: var(--section-color);
}

.grid-col-row-inputs::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  opacity: 0.5;
}

.grid-col-row-inputs:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  opacity: 0.5;
  opacity: 0.5;
}

.grid-col-row-inputs::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  opacity: 0.5;
  opacity: 0.5;
}

.grid-col-row-inputs:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  opacity: 0.5;
}

.grid-col-row-inputs::-ms-input-placeholder {
  /* Microsoft Edge */
  opacity: 0.5;
}

.grid-col-row-inputs::placeholder {
  /* Most modern browsers support this now. */
  opacity: 0.5;
}